<template>
  <div id="Touch2Reveal" ref="Touch2Reveal"></div>
</template>

<script>
export default {
  name: "scratchCard",
  data() {
    return {
      canvas: null
    };
  },
  methods: {
    Touch2Reveal: function(
      root = document.getElementById("Touch2Reveal"),
      topSrc,
      bottomSrc,
      options = {
        brushSize: window.innerWidth / 5,
        softBrush: true,
        ratioToShow: 0.5,
        onReveal: function() {}
      }
    ) {
      if (!root) return false;

      //  INIT

      var init = function() {
        root.appendChild(bottom);

        let canvasW = window.innerWidth;
        let canvasH = canvasW * 1.24;

        canvas.width = canvasW;
        canvas.height = canvasH;

        context.drawImage(top, 0, 0, canvasW, canvasH);

        if (!options.revealTarget)
          options.revealTarget = {
            x: 0,
            y: 0,
            width: canvas.width,
            height: canvas.height
          };
      };

      this.reset = function() {
        context.drawImage(top, 0, 0, top.width, top.height);
      };

      //  OPERATIONS

      var distanceBetween = function(point1, point2) {
        return Math.sqrt(Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2));
      };
      var angleBetween = function(point1, point2) {
        return Math.atan2(point2.x - point1.x, point2.y - point1.y);
      };
      var getImageDataAlphaRatio = function(imageData, alphaIndex = 4) {
        let alphaTotal = 0;
        let pixelArray = imageData.data;
        let len = pixelArray.length;
        const maxAlphaValue = 255;
        const alphaMax = (len / alphaIndex) * maxAlphaValue;
        for (let i = alphaIndex - 1; i < len; i += alphaIndex) {
          alphaTotal += pixelArray[i];
        }
        let alphaRatio = alphaTotal / alphaMax;
        return alphaRatio;
      };

      //  EVENTS

      const queueTarget = 2;
      var queueProgress = 0;
      var imageQueue = function() {
        queueProgress++;
        if (queueProgress == queueTarget) init();
      };

      var onMouseDown = function(e) {
        onDown(e, e.clientX, e.clientY);
        canvas.addEventListener("mousemove", onMouseMove);
      };
      var onTouchDown = function(e) {
        onDown(e, e.touches[0].clientX, e.touches[0].clientY);
        canvas.addEventListener("touchmove", onTouchMove);
      };
      var onDown = function(e, _x, _y) {
        e.preventDefault();
        context.globalCompositeOperation = "destination-out";
        lastPoint = { x: _x, y: _y };

        if (options.softBrush == false) {
          context.beginPath();
          context.lineWidth = options.brushSize;
          context.moveTo(lastPoint.x, lastPoint.y);
        }

        return false;
      };

      //  ON MOVE

      var onMouseMove = function(e) {
        onMove(e, e.clientX, e.clientY);
      };
      var onTouchMove = function(e) {
        onMove(e, e.touches[0].clientX, e.touches[0].clientY - 125); //位置飄移
      };
      var onMove = function(e, _x, _y) {
        e.preventDefault();

        var currentPoint = { x: _x, y: _y };
        var dist = distanceBetween(lastPoint, currentPoint);
        var angle = angleBetween(lastPoint, currentPoint);

        if (options.softBrush == false) {
          context.lineTo(currentPoint.x, currentPoint.y);
          context.stroke();
        } else {
          for (var i = 0; i < dist; i += options.brushSize) {
            let x = lastPoint.x + Math.sin(angle) * i;
            let y = lastPoint.y + Math.cos(angle) * i;

            var radgrad = context.createRadialGradient(x, y, options.brushSize / 10, x, y, options.brushSize / 2);

            radgrad.addColorStop(0, "rgba(0,0,0,.2)");
            radgrad.addColorStop(0.5, "rgba(0,0,0,0.1)");
            radgrad.addColorStop(1, "rgba(0,0,0,0)");

            context.fillStyle = radgrad;
            context.fillRect(
              x - options.brushSize / 2,
              y - options.brushSize / 2,
              options.brushSize,
              options.brushSize
            );
          }
        }

        lastPoint = currentPoint;

        return false;
      };

      //  ON UP

      var onMouseUp = function(e) {
        canvas.removeEventListener("mousemove", onMouseMove);
        onUp(e);
      };
      var onTouchUp = function(e) {
        canvas.removeEventListener("touchmove", onTouchMove);
        onUp(e);
      };
      var onUp = function(e) {
        e.preventDefault();
        let alphaRatio = getImageDataAlphaRatio(
          context.getImageData(
            options.revealTarget.x,
            options.revealTarget.y,
            options.revealTarget.width,
            options.revealTarget.height
          )
        );

        context.globalCompositeOperation = "source-over";

        if (alphaRatio < options.ratioToShow) options.onReveal();

        return false;
      };

      //  CONFIG

      if (!options.brushSize) options.brushSize = window.innerWidth / 5;
      if (!options.softBrush) options.softBrush = true;
      if (!options.ratioToShow) options.ratioToShow = 0.5;
      if (!options.onReveal) options.onReveal = function() {};

      const canvas = document.createElement("canvas");
      root.appendChild(canvas);

      const context = canvas.getContext("2d");
      if (options.softBrush == false) context.lineJoin = context.lineCap = "round";

      const top = document.createElement("img");
      top.addEventListener("load", imageQueue);
      top.src = topSrc;

      const bottom = document.createElement("img");
      bottom.addEventListener("load", imageQueue);
      bottom.src = bottomSrc;

      canvas.addEventListener("mousedown", onMouseDown);
      canvas.addEventListener("touchstart", onTouchDown);
      canvas.addEventListener("mouseup", onMouseUp);
      canvas.addEventListener("touchend", onTouchUp);
      canvas.addEventListener("touchcancel", onTouchUp);
      canvas.addEventListener("touchleave", onTouchUp);

      //  SHORTCUTS

      var lastPoint;

      return canvas;
    }
  },
  mounted() {
    this.canvas = this.Touch2Reveal(this.$refs.Touch2Reveal, "img/scratch.jpg", "img/scratch_f.jpg");
  }
};
</script>

<style lang="scss">
#Touch2Reveal {
  position: relative;
}

#Touch2Reveal > canvas {
  position: absolute;
  top: 0;
  left: 0;
}

#Touch2Reveal > canvas {
  z-index: 2;
}

#Touch2Reveal > img {
  z-index: 1;
  width: 100%;
}
</style>
