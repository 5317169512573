<template>
  <div class="full-page overflow-auto" ref="container">
    <FavorContainer
      v-if="flag('stage_2_phase1Clear') && flag('stage_2_step_1_favor') == false"
      @close="stage2step1FavorOver"
      :stage="stage"
      :step="1"
      :maximum_step="3"
      :stageFinished="false"
    ></FavorContainer>
    <FavorContainer
      v-if="flag('stage_2_findDancePlace') && flag('stage_2_findDancePlace_favor') == false"
      @close="stage2findPlaceFavorOver"
      :stage="stage"
      :step="3"
      :maximum_step="3"
      :stageFinished="false"
    ></FavorContainer>
    <!-- ＴＦＧ -->
    <FavorContainer
      v-if="flag('stage_2_phase4Clear') && flag('stage_2_step_4_favor') == false"
      @close="phase4step4FavorOver"
      :stage="stage"
      :step="4"
      :maximum_step="3"
      :stageFinished="false"
    ></FavorContainer>
    <!-- 關卡完成 -->
    <FavorContainer
      v-if="flag('stage_2_clear') && flag('stage_2_step_4_favor_over') == false"
      @close="phase4bonusDone"
      :stage="stage"
      :step="3"
      :maximum_step="1"
      :stageFinished="true"
    ></FavorContainer>
    <div class="position-relative stage-content" ref="stage">
      <stageHeader :character="stageCharacter" :stageLocation="stageLocation"></stageHeader>
      <moreContent @click="showProgressUpdate = false" v-if="showProgressUpdate"></moreContent>
      <scratchCard></scratchCard>
      <div class="scroll-btns" v-if="scrollBtnsShow">
        <a
          role="button"
          class="scroll-btn"
          v-scroll-to="{
            el: '#app',
            container: '.full-page',
            duration: 300,
            force: true,
            cancelable: true,
            x: false,
            y: true
          }"
          ><img src="img/icon-arrow-s.svg" alt=""
        /></a>
        <a
          role="button"
          class="scroll-btn scroll-down"
          v-scroll-to="{
            el: '#app',
            container: '.full-page',
            duration: 300,
            offset: 300,
            force: true,
            cancelable: true,
            x: false,
            y: true
          }"
        >
          <img src="img/icon-arrow-s.svg" alt="" />
        </a>
      </div>

      <stageMessage
        :user="user"
        :stageCharacter="siteCharacter.name"
        :data="phase1Messages"
        :stage="stage"
        :step="1"
        hint_key="stage_2_phase1_hint"
        v-on:stageClear="phase1MsgClear"
        v-on:hintAdd="parse1Hint"
      ></stageMessage>
      <checkPosition
        v-if="flag('stage_2_phase1Clear')"
        :location="check1stPosition"
        :local="[25.036854, 121.517439]"
        :zoom="17"
      ></checkPosition>
      <template v-if="flag('stage_2_check1stPlace')">
        <igStory :video="phase1IgStory"></igStory>
        <stageMessageNoInput :data="phase1IgStoryMsg"></stageMessageNoInput>
      </template>
      <template v-if="flag('stage_2_phase2')">
        <!--<igStory :video="dancePreviewVideo"></igStory>-->
        <igStory :video="dancePreviewVideo"></igStory>

        <stageMessageNoInput :data="dancePreview"></stageMessageNoInput>
        <slider :slides="placesSlide"></slider>
        <stageMessageNoInput :data="placeFindMsg"></stageMessageNoInput>
        <div class="unlock-block position-relative">
          <!-- <a role="button" class="hint-20">?</a> -->
          <div class="unblock-inside d-flex position-relative justify-content-center">
            <a @click="checkDancePosition" class="d-flex align-items-center justify-content-center">
              <div>
                <img class="" src="/img/location-unlock.svg" alt="" />
                <div class="mt-2 icon-intro">尋找她的身影</div>
              </div>
            </a>
          </div>
        </div>
      </template>
      <template v-if="flag('stage_2_findDancePlace')">
        <igStoryGoGame :video="findDance1IgStory" @goGame="goGame"></igStoryGoGame>
        <stageMessageNoInput :data="findDanceMsg"></stageMessageNoInput>
      </template>
      <template v-if="flag('stage_2_dancingRule')">
        <div class="d-flex position-relative justify-content-center py-5">
          <a class="d-flex flex-column align-items-center justify-content-center" @click="goDancingRule">
            <div class="text-center">
              <img class="" src="/img/icon-finish.svg" alt="" />
              <div class="mt-2 icon-intro">繼續關卡</div>
            </div>
          </a>
        </div>
      </template>
      <template v-if="flag('stage_2_danceFinish')">
        <winnerCharacter :winnerCharacter="flag('stage_2_danceWinner')" :winnerTalk="winnerTalk"></winnerCharacter>
        <photoMission :photo="photoMission" :stage="stage" @next="photoMissionOver">
          <stageMessageNoInput :data="photoMissionMsg"></stageMessageNoInput>
        </photoMission>
      </template>
      <template v-if="flag('stage_2_photoMissionFinish')">
        <img v-if="!flag('stage_2_phase4Clear')" class="img-fluid" src="/img/tfg.jpg" alt="" />
        <img v-else class="img-fluid" src="/img/stage2phase4-answer.png" alt="" />
        <stageMessage
          :user="user"
          :stageCharacter="siteCharacter.name"
          :data="phase4Messages"
          :stage="stage"
          :step="4"
          hint_key="stage_2_phase4_hint"
          v-on:stageClear="phase4MsgClear"
          v-on:hintAdd="parse4Hint"
        ></stageMessage>
      </template>
      <div class="unlock-block position-relative" v-show="flag('stage_2_phase4Clear')">
        <!-- <a role="button" class="hint-20">?</a> -->
        <div class="unblock-inside d-flex position-relative justify-content-center">
          <a class="d-flex align-items-center justify-content-center" @click="stage2Clear">
            <div>
              <img class="" src="/img/icon-finish.svg" alt="" />
              <div class="mt-2 icon-intro">完成關卡</div>
            </div>
          </a>
        </div>
      </div>
      <mainMenu></mainMenu>
    </div>
  </div>
</template>

<script>
import Avatar from "@/Mixins/Avatar";
import mainMenu from "@/views/part/mainMenu";
import stageHeader from "@/views/part/stageHeader";
import checkPosition from "@/views/part/checkPosition";
import igStoryGoGame from "@/views/part/igStoryGoGame";
import winnerCharacter from "@/views/part/winnerCharacter";
import moreContent from "@/views/part/moreContent";

import CharacterFromVuex from "@/Mixins/CharacterFromVuex";
import stageMessage from "@/views/part/stageMessage";
import stageMessageNoInput from "@/views/part/stageMessageNoInput";
import igStory from "@/views/part/igStory";
import slider from "@/views/part/slider";
import scratchCard from "@/views/part/scratchCard";
import photoMission from "@/views/part/photoMission";

import { mapGetters, mapActions } from "vuex";
import getDistance from "..//Mixins/getDistance";
import WebSocketMixin from "../Mixins/WebSocketMixin";
import StageFromMixin from "../Mixins/StageFromMixin";
import ShowUpdate from "@/Mixins/ShowUpdateMixin";
import TeamFromVuex from "../Mixins/TeamFromVuex";
import FavorContainer from "./favor/FavorContainer";
import UserFromVuex from "@/Mixins/UserFromVuex";
import FavorFromMixin from "@/Mixins/FavorFromMixin";
import { getCurrentPosition } from "../libs/getCurrentPosition";

export default {
  name: "cks",
  mixins: [
    Avatar,
    CharacterFromVuex,
    WebSocketMixin,
    StageFromMixin,
    getDistance,
    ShowUpdate,
    TeamFromVuex,
    UserFromVuex,
    FavorFromMixin
  ],
  components: {
    mainMenu,
    stageHeader,
    igStory,
    checkPosition,
    stageMessage,
    stageMessageNoInput,
    scratchCard,
    slider,
    photoMission,
    igStoryGoGame,
    winnerCharacter,
    moreContent,
    FavorContainer
  },
  computed: {
    ...mapGetters("Character", ["getRoleByName"]),
    stage_2_phase2() {
      return this.flag("stage_2_phase2");
    },
    winnerTalk() {
      switch (this.flag("stage_2_danceWinner")?.name) {
        case "小希":
          return "我也可以是最亮眼的！";
        case "花輪":
          return "受不了的酷！";
        case "赤木":
          return "叫我亞洲舞王！";
        case "白爛":
          return "跟我一起練舞功！";
        case "春哥":
          return "我是你最安心的後盾";
        case "胖子":
          return "我是全世界最靈活的胖子！";
        default:
          return "";
      }
    },
    stageCharacter() {
      return this.getRoleByName(this.siteCharacter.name);
    }
  },
  data() {
    return {
      showProgressUpdate: false,
      showProgressUpdateFlags: [
        "stage_2_phase1Clear",
        "stage_2_phase2",
        "stage_2_check1stPlace",
        "stage_2_findDancePlace",
        "stage_2_photoMissionFinish",
        "stage_2_phase4Clear"
      ],
      stage: {
        stage_id: 2
      },
      siteCharacter: {
        name: "赤木"
      },
      stageLocation: {
        time: "高一下",
        location: "中正紀念堂"
      },
      check1stPosition: {
        nextPhase: "stage_2_check1stPlace",
        position: [25.036854, 121.517439],
        distance: 80
      },
      phase1Messages: {
        msgLikes: "赤木和其他20個人都說讚",
        character: "赤木",
        defaultMsg: [
          {
            name: "赤木",
            content: "陽光高中生的週末，當然是約女校的同學一起出門玩啦！"
          },
          {
            name: "赤木",
            content: "不過這麼熱的天…汗流得到處都是，<strong>頭髮都塌了，得快點把頭髮抓起來</strong>"
          },
          {
            name: "赤木",
            content: "到底出門做什麼好啊？"
          }
        ],
        answer: ["歌舞青春", "鴿舞青唇", "鴿五青唇", "鴿五親唇", "鴿舞親唇"],
        echoOfanswer: "對！歌舞青春！這個提議好！",
        hintIndex: 0,
        hint: [
          "（1/4）幫畫面中的人物整理頭髮之後，底下好像有不同的圖片出現了。試著用四個文字敘述底層圖片出現的內容<br>（使用提示會減少得到的好感值）",
          "（2/4）跟數量和顏色有關<br>（使用提示會減少得到的好感值）",
          { content: "下個提示很接近正確答案囉！再次點擊提示按鈕查看" },
          "（3/4）幾隻鴿子跟什麼顏色的嘴唇<br>（使用提示會減少得到的好感值）",
          { content: "放棄了嗎？再次點擊提示按鈕查看答案" },
          "鴿五青唇"
        ]
      },
      phase1IgStory: {
        src: "S02-1",
        poster: "mc/3.jpg",
        nextPhase: "stage_2_phase2"
      },
      phase1IgStoryMsg: [
        {
          name: "赤木",
          content: "沒想到大家都來探班小桃練舞了！我今天一定要搶在所有人之前當第一個！小桃收到我的飲料一定會超感動！"
        }
      ],
      dancePreviewVideo: {
        src: "Dance-short",
        poster: "mc/21.jpg",
        nextPhase: ""
      },
      dancePreview: [
        {
          name: "赤木",
          content: "這是小桃這次要練的舞步，她應該會找個適合的場所練習吧！"
        }
      ],
      placesSlide: {
        width: 1024,
        height: 1024,
        imgs: ["cks-place1.jpg", "cks-place2.jpg", "cks-place3.jpg"]
      },
      placeFindMsg: [
        {
          name: "赤木",
          content: "小桃會在哪裡練舞呢？我記得她說過，<strong>音樂廳和戲劇院對她們來說都一樣、都可以練</strong>"
        },
        {
          name: "赤木",
          content: "大廣場的場地比較大，適合練習跑位跟隊型變換"
        },
        {
          name: "赤木",
          content: "兩廳院的正門口有鏡面，適合練習動作比較小的舞步，或是需要調整舞步姿勢的人"
        },
        {
          name: "赤木",
          content: "兩廳院的背面通常人比較少，跳比較激烈的舞步也不怕干擾到旁人"
        },
        {
          name: "赤木",
          content: "我知道了！小桃一定在<strong>那個地方</strong>練舞！GOGOGO我來了😎 "
        }
      ],
      dancePosition: [
        // 大廣場
        {
          distance: 15,
          locations: [
            [25.036653, 121.518832],
            [25.036578, 121.519079],
            [25.035822, 121.518298],
            [25.035642, 121.518706]
          ]
        },
        {
          // 音樂廳的背面
          distance: 20,
          locations: [
            [25.035007, 121.518049],
            [25.035092, 121.517841],
            [25.034985, 121.518259]
          ]
        },
        {
          //音樂廳的正門
          distance: 30,
          locations: [
            [25.035508, 121.518336],
            [25.035612, 121.518148],
            [25.035449, 121.518543],
            [25.03684, 121.5191],
            [25.036936, 121.518932],
            [25.036758, 121.519303]
          ]
        }
      ],
      photoMission: {
        img: "photo-cks.jpg",
        finish: "stage_2_photo_finished",
        flag: "stage_2_finished",
        local: [25.035013, 121.520907],
        flyTo: [25.035013, 121.520907]
      },
      findDance1IgStory: {
        src: "S02-2",
        poster: "mc/4.jpg",
        stageFlag: "stage_2_goDancing"
      },
      findDanceMsg: [
        {
          name: "赤木",
          content:
            "大家你一言我一句的出意見，對小桃一點幫助也沒有啊！只是造成小桃困擾而已，不如我們就選出一個最會跳的人來幫她，其他人就安靜在旁邊待著，好不好？我們現在就來看誰最會跳！"
        }
      ],
      photoMissionMsg: [
        {
          name: "赤木",
          content: "比賽不到最後一刻，決不輕言放棄，一旦死心的話，比賽就提前結束了！"
        },
        {
          name: "赤木",
          content: "為了妳，我永遠會奮鬥到最後！"
        },
        {
          name: "提示",
          content:
            "你們可以參考範例照片的地點和姿勢、重現當年的回憶；或是自行選擇你們喜愛的地點、姿勢或方式，拍照並上傳照片，<strong>照片請用正方形格式拍攝，超出將會被剪裁。</strong>"
        }
      ],
      phase4Messages: {
        unlockFlag: "stage_2_phase4Clear",
        msgLikes: "赤木和其他20個人都說讚",
        character: "赤木",
        defaultMsg: [
          {
            name: "小彥",
            content: "嘿嘿！我把你們練舞的蠢姿勢都拍起來了"
          },
          {
            name: "白爛",
            content: "你是不是看不慣我帥氣的臉龐！"
          },
          {
            name: "小桃",
            content: "咦！這幾個姿勢看起來不就很像那個嗎？"
          }
        ],
        answer: ["Tfg", "tfg", "TFg", "TFG", "tfg"],
        echoOfanswer: ["對！TFG就是北一女的英文縮寫嘛", "說到北一女，就一定要來說說我的故事啦！"],
        echoOfCharacter: ["小桃", "白爛"],
        hintIndex: 0,
        hint: [
          "（1/4）這三個人的姿勢有點像文字<br>（使用提示會減少得到的好感值）",
          "（2/4）好像是英文字母<br>（使用提示會減少得到的好感值）",
          { content: "下個提示很接近正確答案囉！再次點擊提示按鈕查看" },
          "（3/4）最右側人的姿勢好像只需要看手部動作<br>（使用提示會減少得到的好感值）",
          { content: "放棄了嗎？再次點擊提示按鈕查看答案" },
          "TFG"
        ]
      },
      scrollBtnsShow: true
    };
  },
  mounted() {
    this.$refs.container.scrollTop = this.$refs.stage.scrollHeight;
    this.$refs.container.addEventListener("scroll", this.handleScroll);
  },
  updated() {
    // if(this.$refs.container.scrollTop>400){
    //   this.$refs.container.scrollTop = this.$refs.stage.scrollHeight;
    // }
  },
  beforeDestroy() {
    this.$refs.container?.removeEventListener?.("scroll", this.handleScroll);
  },
  watch: {
    stage_2_phase2(value, oldVal) {
      if (value !== oldVal) {
        this.fakeFullScreen({ status: false });
      }
    }
  },
  methods: {
    ...mapActions("Video", ["fakeFullScreen"]),

    stage2step1FavorOver() {
      this.setFlag({ key: "stage_2_step_1_favor", status: true });
    },
    stage2findPlaceFavorOver() {
      this.setFlag({ key: "stage_2_findDancePlace_favor", status: true });
    },
    phase4step4FavorOver: function() {
      this.setFlag({ key: "stage_2_step_4_favor", status: true });
    },
    phase4bonusDone: function() {
      this.setFlag({ key: "stage_2_step_4_favor_over", status: true });
      this.$router.replace("homeMap");
    },
    handleScroll() {
      let scrollTop = this.$refs.container.scrollTop;
      if (scrollTop > 300) {
        this.scrollBtnsShow = false;
      } else {
        this.scrollBtnsShow = true;
      }
      if (this.showProgressUpdate) {
        this.showProgressUpdate = false;
      }
    },
    final() {
      this.unlockStage(2);
      this.setFlag({ key: "stage_1_photo_finished", status: true });
      // this.setFlag({ key: "stage_2_location", status: [25.040665, 121.516658] });
    },
    parse1Hint() {
      // this.hintMapAdd("stage_2_phase1_hint");
      this.phase1Messages.hintIndex++;
    },
    phase1MsgClear: function() {
      if (this.flag("stage_2_phase1Clear")) return;
      this.setFlag({ key: "stage_2_phase1Clear", status: true });
      this.addScoreByRate({
        score: 10,
        characterName: this.character?.name,
        isMoveStage: false,
        hint_key: "stage_2_phase1_hint"
      });
    },
    parse4Hint() {
      // this.hintMapAdd("stage_2_phase4_hint");
      this.phase4Messages.hintIndex++;
    },
    phase4MsgClear: function() {
      if (this.flag("stage_2_phase4Clear")) return;
      this.setFlag({ key: "stage_2_phase4Clear", status: true });
      this.addScoreByRate({
        score: 10,
        characterName: this.character?.name,
        isMoveStage: false,
        hint_key: "stage_2_phase4_hint"
      });
    },
    checkDancePosition: function() {
      if (navigator.geolocation) {
        getCurrentPosition(
          position => {
            let p = position.coords;
            let characterName = this.character.name;
            let userCenter = [p.latitude, p.longitude];
            let dancePositions = this.dancePosition;
            if (this.flag("stage_2_findDancePlace") == false) {
              let checkAnswer = locations => {
                for (let index in locations) {
                  let item = locations[index];
                  let distance = this.getDistance(userCenter[0], userCenter[1], item[0], item[1], "M");
                  let near_enough = distance <= dancePositions[0].distance;
                  if (near_enough) {
                    this.placeFindMsg.push({
                      name: characterName,
                      content: "看到她了！果然是這裡沒錯！"
                    });
                    // this.$router.replace('danceRule');

                    this.setWinner({
                      winner: this.character,
                      user: this.user,
                      stage: this.stage,
                      step: 1
                    });
                    this.setFlag({ key: "stage_2_findDancePlace", status: true });
                    this.addScoreByRate({
                      score: 10,
                      characterName: this.character?.name,
                      isMoveStage: true,
                      hint_key: "stage_2_phase2_hint"
                    });
                    return;
                  }
                }
              };
              checkAnswer(dancePositions[2].locations);
              checkAnswer(dancePositions[1].locations);
              for (let index in dancePositions[0].locations) {
                let item = dancePositions[0].locations[index];
                let distance = this.getDistance(userCenter[0], userCenter[1], item[0], item[1], "M");
                console.log(distance);
                if (distance <= dancePositions[0].distance) {
                  this.placeFindMsg.push({
                    name: characterName,
                    content: "嗚～沒看到她，難道不是在這裡嗎？"
                  });
                  this.hintMapAdd("stage_2_phase2_hint");
                  return;
                }
              }
              this.placeFindMsg.push({
                name: characterName,
                content: "這裡不是適合練舞的地點，她一定不會在這裡的"
              });
              this.hintMapAdd("stage_2_phase2_hint");
            } else {
              this.placeFindMsg.push({
                name: characterName,
                content: "已經找到小桃了，快把飲料給小桃！"
              });
            }
          },
          err => {
            alert(err.message);
          }
        );
      } else {
        alert("您的瀏覽器不支援地理定位");
      }
    },
    goGame: function() {
      this.setFlag({ key: "stage_2_dancingRule", status: true });
    },
    goDancingRule() {
      if (this.flag("stage_2_danceFinish")) {
        this.$router.replace("danceResult");
        return;
      }
      this.$router.replace("danceRule");
    },
    stage2Clear: function() {
      if (this.flag("stage_2_clear")) {
        this.$router.replace("homeMap");
        return;
      }
      this.setFlag({ key: "stage_2_clear", status: true });
      this.final();
    },
    photoMissionOver() {
      this.setFlag({ key: "stage_2_photoMissionFinish", status: true });
      this.setFlag({ key: "stage_2_photo_finished", status: true });
    }
  }
};
</script>

<style scoped lang="scss">
video {
  max-width: 100%;
}
</style>
